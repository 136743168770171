import { Spacer } from "~/widgets/spacer";
import { DividerV2 } from "~/widgets/divider";
import { VoucherDetails } from "~/server/types/order";
import { Card } from "../gift_card";
import { formatDateLongWithTime } from "~/utils/date";
import { JSX, Show } from "solid-js";
import { useModal } from "~/components/modal";
import { HowToUseContent } from "~/components/brand_l2/how_to_use_content";
import { Tncs } from "~/components/brand_l2/tncs";
import { HowToUseSteps } from "~/server/types/brand";

export function ProfileCardsList(props: { cardList: VoucherDetails[] }) {
  return (
    <>
      {props.cardList.map((card, index) => (
        <>
          <Show when={(card.generatedDateTime ?? "").trim().length > 0}>
            <span class="text-medium text-textNormal">
              {formatDateLongWithTime(new Date(`${card.generatedDateTime}z`))}
            </span>
          </Show>
          <Spacer height={8} />
          <ProfileCard card={card} />
          <Spacer height={12} />
          <SecondaryCtas
            tncResId={card.voucherMetadata.tncResId}
            brandId={card.brandId}
          />
          <Spacer height={32} />
          <Show
            fallback={<div class="mt-4"></div>}
            when={index < props.cardList.length - 1}
          >
            <div class="h-[5px] w-full scale-x-125 bg-basePrimaryDark">
              &nbsp;
            </div>
            <Spacer height={32} />
          </Show>
        </>
      ))}
    </>
  );
}

function ProfileCard(props: { card: VoucherDetails }) {
  return (
    <Card
      brand={{
        brandId: props.card.brandId,
        brandKey: props.card.brandId,
        brandName: props.card.voucherMetadata.title,
        cardBackgroundColor: props.card.voucherMetadata.cardBackgroundColor,
        heroImageUrl: props.card.voucherMetadata.heroImageUrl,
        deeplink: props.card.voucherMetadata.addVoucherBrandPageUrl,
        plainLogoUrl: props.card.voucherMetadata.plainLogoUrl,
        tncResId: props.card.voucherMetadata.tncResId,
      }}
      voucher={{
        redemptionType: props.card.voucherMetadata.voucherRedemptionType,
        cardNumber: props.card.cardNumber,
        pin: props.card.cardPin,
        amount: props.card.amount,
        expiryDate: props.card.expiryDate ?? "",
      }}
      amount={props.card.amount}
      isPreview={false}
      highlightCardDetails={() => false}
      defaultEventProps={{}}
      isExpired={false}
    />
  );
}

type SecondaryCtasProps = {
  brandId?: string;
  howToUseSteps?: HowToUseSteps[];
  tncResId: string;
};

export function SecondaryCtas(props: SecondaryCtasProps) {
  const { setIsModalOn, updateModalContentGetter, setThemeMode } = useModal()!;

  return (
    <div class="flex w-full flex-row gap-2  text-textNormal">
      {buildButton({
        onClick: () => {
          updateModalContentGetter(() => {
            return (
              <HowToUseContent
                brandId={props.brandId}
                howToUseStepsList={props.howToUseSteps!}
              />
            );
          });

          setThemeMode("dark");

          setIsModalOn(true);
        },
        child: () => (
          <div class="flex flex-row items-center justify-center gap-1 text-mediumBold ">
            <HowToUseIcon />
            How to use
          </div>
        ),
      })}
      {buildButton({
        onClick: () => {
          updateModalContentGetter(() => {
            return <Tncs tncResId={props.tncResId} />;
          });

          setThemeMode("dark");

          setIsModalOn(true);
        },
        child: () => (
          <div class="flex flex-row items-center justify-center gap-1 text-mediumBold ">
            <TnCIcon />
            T&Cs
          </div>
        ),
      })}
    </div>
  );

  function buildButton(props: {
    onClick: () => void;
    child: () => JSX.Element;
  }) {
    return (
      <div
        onClick={props.onClick}
        class="flex-1 grow cursor-pointer items-center justify-center rounded-full border border-basePrimaryDark bg-[#020202] px-2 py-[6px]"
      >
        {props.child()}
      </div>
    );
  }
}

function HowToUseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M12.5001 12.7117L13.8277 11.9742C14.4902 11.6062 14.9011 10.9079 14.9011 10.15C14.8226 8.88966 13.7418 7.92889 12.481 7.99869C11.3544 7.95192 10.354 8.71348 10.0991 9.81186"
        stroke="#7C7C7C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle
        cx="12.5003"
        cy="12.0003"
        r="9.00375"
        stroke="#7C7C7C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.6002 15.9017C12.6002 15.9569 12.5554 16.0016 12.5002 16.0016C12.4449 16.0016 12.4002 15.9568 12.4002 15.9016C12.4001 15.8464 12.4449 15.8016 12.5001 15.8015C12.5267 15.8015 12.5522 15.812 12.5709 15.8308C12.5897 15.8496 12.6003 15.8751 12.6002 15.9017"
        stroke="#7C7C7C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function TnCIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.914 6.414L16.086 3.586C15.711 3.211 15.202 3 14.672 3H7.5C6.395 3 5.5 3.895 5.5 5V19C5.5 20.105 6.395 21 7.5 21H17.5C18.605 21 19.5 20.105 19.5 19V7.828C19.5 7.298 19.289 6.789 18.914 6.414V6.414Z"
        stroke="#7C7C7C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.5 8H15.5C14.948 8 14.5 7.552 14.5 7V3"
        stroke="#7C7C7C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.5 11H14.5"
        stroke="#7C7C7C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.5 14H14.5"
        stroke="#7C7C7C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.5 17H12.83"
        stroke="#7C7C7C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
